import React, { useEffect, useState } from "react";
import { withRouter, Route } from "react-router-dom";
import Header from "../../components/Header";

import SubHeaderSearch from "../../components/SubHeaderSearch";
import BookingFinished from "../BookingFinished";
import HotelDetails from "../HotelDetails";
import Result from "../Result";
import Summary from "../Summary";
import Footer from "../../components/Footer";
import BookingError from "../BookingError";
import ExperienceDetails from "../ExperienceDetails";
import FooterPorto from "../../components/FooterPorto";
import { getBrandStorage } from "../../utils/getBrand";
import { Box } from "@material-ui/core";
import { useGlobal } from "../../contexts/globalContext";

import { getSessionStorage } from "../../utils/getSessionStorage";

function Search() {
	const [buscaAtual, setBuscaAtual] = useState("");
	const [searchState, setSearchState] = useState();

	const { user } = useGlobal();

	useEffect(() => {
		setBuscaAtual(getSessionStorage("buscaAtual"));
	}, []);

	useEffect(() => {
		switch (buscaAtual) {
			case "Aéreo":
				setSearchState(getSessionStorage("flySearch"));
				break;
			case "Carro":
				setSearchState(getSessionStorage("carSearch"));
				break;
			case "Hotel":
				setSearchState(getSessionStorage("hotelSearch"));
				break;
			case "Experiencias":
				setSearchState(getSessionStorage("experienceSearch"));
				break;
			default:
				break;
		}
	}, [buscaAtual]);
	return (
		<Box
			style={{
				backgroundColor: "#f2f2f2",
				minHeight: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box>
				<Header />
				{window.location.pathname !== "/busca/booking-error" &&
					window.location.pathname !== "/busca/finalizado" &&
					user.campaign?.campaignConfigLayout?.subHeaderSearch && <SubHeaderSearch searchState={searchState} buscaAtual={buscaAtual} />}

				<Route path="/busca/resultado-pesquisa">
					<Result searchState={searchState} buscaAtual={buscaAtual} />
				</Route>
				<Route path="/busca/hotel-details">
					<HotelDetails searchState={searchState} buscaAtual={buscaAtual} />
				</Route>
				<Route path="/busca/experience-details">
					<ExperienceDetails buscaAtual={buscaAtual} />
				</Route>
				<Route exact path="/busca/resumo" component={Summary}>
					<Summary searchState={searchState} buscaAtual={buscaAtual} />
				</Route>
				<Route exact path="/busca/finalizado">
					<BookingFinished searchState={searchState} buscaAtual={buscaAtual} />
				</Route>
				<Route exact path="/busca/booking-error">
					<BookingError searchState={searchState} buscaAtual={buscaAtual} />
				</Route>
			</Box>

			{getBrandStorage() === "portoplus" ? <FooterPorto /> : <Footer />}
		</Box>
	);
}

export default withRouter(Search);
