import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as S from './styles';

function CarouselComponent({ type, images = [] }) {
  return (
    <S.Wrapper>
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        width={'100%'}
        fixedWidth
        statusFormatter={(current, total) => `${current} de ${total}`}
        showStatus={images.length > 1 ? true : false}
        {...(type ? 'centerMode' : '')}
      >
        {images.map((image) => {
          return (
            <div>
              <img
                className={`image ${type ? type : ''}`}
                src={image}
                alt='foto do quarto'
              />
              {/* <p className='legend'>Legend 1</p> */}
            </div>
          );
        })}
      </Carousel>
    </S.Wrapper>
  );
}

export default CarouselComponent;
