import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: #f2f2f2;
    font-family: 'Poppins';
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;

    margin: 0;
    padding: 0;
  `}
`;

export const Title3 = styled.h3`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;
  `}
`;

export const Descricao = styled.span`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;
  `}
`;

export const CarouselContainer = styled.h1`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `}
`;

export const ExperienceName = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    padding: 30px;
    padding-bottom: 10px;
    padding-top: 10px;

    ${theme.breakpoints.down('md')} {
      padding: 0px;
    }

    span {
      width: 100%;
    }

    .location {
      display: flex;
      align-content: center;
      font-size: 12px;
      font-weight: 300;
      margin-top: 5px;
    }
  `}
`;

export const InformacoesUteis = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    padding-top: 0;

    ${theme.breakpoints.down('md')} {
      padding: 0px;
    }

    span {
      width: 100%;
    }
  `}
`;

export const CheckInOut = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

export const Amenities = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  span {
    width: 100%;
    display: flex;
    svg {
      margin-right: 5px;
    }
  }

  .child {
    margin-left: 26px;
  }
`;

export const ValueInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 30px;
    flex-wrap: wrap;
    width: 100%;

    .choose-hotel {
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      .old-value {
        color: #000;
        opacity: 65%;
        font-size: 14px;
        width: unset;
        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }

        .value {
          text-decoration: line-through;
        }
      }

      .final-value {
        font-size: 26px;
        font-weight: 600;
        margin-left: 4px;
        width: unset;
        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }
      }
    }
  `}
`;

export const Personas = styled.div`
  ${({ theme }) => css`
    padding-top: 20px;
    display: flex;

    width: 100%;
    div {
      padding: 5px;
    }
  `}
`;
