import { Grid } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Facebook, Instagram, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../../contexts/globalContext';
import * as S from './styles';

// const footersPorto = [
//   {
//     grupo: 'Serviços',
//     link: 'http://linkaqui.com.br',
//     items: null,
//   },
//   {
//     grupo: 'Links uteis',
//     link: 'http://linkaqui.com.br',
//     items: null,
//   },
//   {
//     grupo: 'Atendimento',
//     link: 'http://linkaqui.com.br',
//     items: null,
//   },
//   {
//     grupo: 'Social',
//     link: 'http://linkaqui.com.br',
//     items: null,
//   },
// ];

// const footers = [
//   {
//     grupo: 'Serviços',
//     link: null,
//     items: [
//       {
//         id: 85,
//         nome: 'Hoteis',
//         link: '/atendimento',
//         icone: null,
//       },
//       {
//         id: 87,
//         nome: 'Aluguel de carros',
//         link: null,
//         icone: null,
//       },
//       {
//         id: 86,
//         nome: 'Passagens aéreas',
//         link: null,
//         icone: null,
//       },
//     ],
//   },
//   {
//     grupo: 'Links uteis',
//     link: null,
//     items: [
//       {
//         id: 88,
//         nome: 'Blog',
//         link: null,
//         icone: null,
//       },
//       {
//         id: 89,
//         nome: 'Institucional',
//         link: null,
//         icone: null,
//       },
//     ],
//   },
//   {
//     grupo: 'Atendimento',
//     link: null,
//     items: [
//       {
//         id: 90,
//         nome: 'Fale Conosco',
//         link: 'https://app.viagensdestinoferias.com.br/atendimento',
//         icone: null,
//       },
//     ],
//   },
//   {
//     grupo: 'Social',
//     link: null,
//     items: [
//       {
//         id: 91,
//         nome: 'WhatsApp',
//         link: 'http://lalala.com.br',
//         icone: '91-icon.png',
//       },
//       {
//         id: 92,
//         nome: 'Facebook',
//         link: null,
//         icone: '92-icon.png',
//       },
//       {
//         id: 93,
//         nome: 'Instagram',
//         link: null,
//         icone: '93-icon.png',
//       },
//     ],
//   },
// ];
const Footer = () => {
  const { user } = useGlobal();

  const getIcon = (name) => {
    switch (name) {
      case 'Instagram':
        return <Instagram fontSize='large' />;
      case 'WhatsApp':
        return <WhatsApp fontSize='large' />;
      case 'Facebook':
        return <Facebook fontSize='large' />;
      default:
        return null;
    }
  };

  return (
			<S.Wrapper>
				<Container>
					<Grid container style={{ width: '100%', justifyContent: 'space-around' }}>
						{user?.campaign?.campaignConfigLayout?.footers?.map((item) =>
							item.items !== null ? (
								<Grid
									item
									xs={12}
									sm={6}
									md={12 / user?.campaign?.campaignConfigLayout?.footers?.length}
									key={item.id}
								>
									<S.FooterTitle>{item.grupo}</S.FooterTitle>
									<S.FooterList>
										{item.items?.map((item) => {
											const icon = getIcon(item.nome);
											return item?.link?.substring(0, 4) !== 'http' ? (
												<Link to={item.link} key={item.id} className={icon ? 'icon' : ''}>
													{icon ? <span>{icon}</span> : <span>{item.nome}</span>}
												</Link>
											) : (
												<a href={item.link} key={item.id} className={icon ? 'icon' : ''}>
													{icon ? <span>{icon}</span> : <span>{item.nome}</span>}
												</a>
											);
										})}
									</S.FooterList>
								</Grid>
							) : (
								user?.campaign?.campaignConfigLayout?.footers?.length && (
									<Grid
										item
										xs={12}
										sm={6}
										md={Math.round(
											12 / user?.campaign?.campaignConfigLayout?.footers?.length
										)}
										style={{ justifyContent: 'center', display: 'flex' }}
									>
										<S.FooterListPorto>
											<a href={item.link}>{item.grupo}</a>
										</S.FooterListPorto>
									</Grid>
								)
							)
						)}
					</Grid>

					<S.CompanyName>
						Destino Férias © 2021 - Todos os direitos reservados - release 60
					</S.CompanyName>
				</Container>
			</S.Wrapper>
		);
};

export default Footer;
