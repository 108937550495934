// eslint-disable-next-line no-sparse-arrays
export const counter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const counter2 = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const MAX_INSTALLMENTS = process.env.REACT_APP_MAX_INSTALLMENTS ? parseInt(process.env.REACT_APP_MAX_INSTALLMENTS) : 10;

export const companies = [
	{ companyName: "Voepass", validatingcarrier: "2Z" },
	{ companyName: "Adria Airways", validatingcarrier: "JP" },
	{ companyName: "Aegean Airlines", validatingcarrier: "A3" },
	{ companyName: "Aer Lingus", validatingcarrier: "EI" },
	{ companyName: "Aero República", validatingcarrier: "P5" },
	{ companyName: "Aeroflot", validatingcarrier: "SU" },
	{ companyName: "Aerolineas Argentinas", validatingcarrier: "AR" },
	{ companyName: "Aeromexico", validatingcarrier: "AM" },
	{ companyName: "Aerosvit Airlines", validatingcarrier: "VV" },
	{ companyName: "Afriqiyah Airways", validatingcarrier: "8U" },
	{ companyName: "Aigle Azur", validatingcarrier: "ZI" },
	{ companyName: "Air Algerie", validatingcarrier: "AH" },
	{ companyName: "Air Astana", validatingcarrier: "KC" },
	{ companyName: "Air Austral", validatingcarrier: "UU" },
	{ companyName: "Air Baltic", validatingcarrier: "BT" },
	{ companyName: "Air Berlin", validatingcarrier: "AB" },
	{ companyName: "Air Canada", validatingcarrier: "AC" },
	{ companyName: "Air China Limited", validatingcarrier: "CA" },
	{ companyName: "Air Europa", validatingcarrier: "UX" },
	{ companyName: "Air France", validatingcarrier: "AF" },
	{ companyName: "Air India (NACIL)", validatingcarrier: "AI" },
	{ companyName: "Air Jamaica Limited", validatingcarrier: "JM" },
	{ companyName: "Air Koryo", validatingcarrier: "JS" },
	{ companyName: "Air Macau", validatingcarrier: "NX" },
	{ companyName: "Air Madagascar", validatingcarrier: "MD" },
	{ companyName: "Air Malta", validatingcarrier: "KM" },
	{ companyName: "Air Mauritius", validatingcarrier: "MK" },
	{ companyName: "Air Moldova", validatingcarrier: "9U" },
	{ companyName: "Air Namibia", validatingcarrier: "SW" },
	{ companyName: "Air New Zealand", validatingcarrier: "NZ" },
	{ companyName: "Air Nigeria", validatingcarrier: "VK" },
	{ companyName: "Air Niugini", validatingcarrier: "PX" },
	{ companyName: "Air Nostrum", validatingcarrier: "YW" },
	{ companyName: "Air One S.p.A.", validatingcarrier: "AP" },
	{ companyName: "Air Pacific", validatingcarrier: "FJ" },
	{ companyName: "Air Seychelles", validatingcarrier: "HM" },
	{ companyName: "Air Tahiti", validatingcarrier: "VT" },
	{ companyName: "Air Tahiti Nui", validatingcarrier: "TN" },
	{ companyName: "Air Transat", validatingcarrier: "TS" },
	{ companyName: "Air Vanuatu", validatingcarrier: "NF" },
	{ companyName: "Air Zimbabwe", validatingcarrier: "UM" },
	{ companyName: "Aircalin", validatingcarrier: "SB" },
	{ companyName: "Airlink", validatingcarrier: "4Z" },
	{ companyName: "Alaska Airlines", validatingcarrier: "AS" },
	{ companyName: "Alitalia", validatingcarrier: "AZ" },
	{ companyName: "All Nippon Airways", validatingcarrier: "NH" },
	{ companyName: "American Airlines", validatingcarrier: "AA" },
	{ companyName: "Arkia Israeli Airlines", validatingcarrier: "IZ" },
	{ companyName: "Armavia", validatingcarrier: "U8" },
	{ companyName: "Asiana", validatingcarrier: "OZ" },
	{ companyName: "Atlas Air", validatingcarrier: "5Y" },
	{ companyName: "Atlasjet Airlines", validatingcarrier: "KK" },
	{ companyName: "Austral", validatingcarrier: "AU" },
	{ companyName: "Austrian", validatingcarrier: "OS" },
	{ companyName: "AVIANCA", validatingcarrier: "AV" },
	{ companyName: "Azerbaijan Airlines", validatingcarrier: "J2" },
	{ companyName: "Azores Airlines", validatingcarrier: "S4" },
	{ companyName: "Azul", validatingcarrier: "AD" },
	{ companyName: "B&H Airlines", validatingcarrier: "JA*" },
	{ companyName: "Bangkok Airways", validatingcarrier: "PG" },
	{ companyName: "Belavia – Belarusian Airlines", validatingcarrier: "B2" },
	{ companyName: "Belle Air", validatingcarrier: "LZ" },
	{ companyName: "Biman", validatingcarrier: "BG" },
	{ companyName: "Binter Canarias", validatingcarrier: "NT" },
	{ companyName: "Blue Air", validatingcarrier: "0B" },
	{ companyName: "Blue Panorama", validatingcarrier: "BV" },
	{ companyName: "bmi", validatingcarrier: "BD" },
	{ companyName: "British Airways", validatingcarrier: "BA" },
	{ companyName: "Brussels Airlines", validatingcarrier: "SN" },
	{ companyName: "Bulgaria air", validatingcarrier: "FB" },
	{ companyName: "C.A.L. Cargo Airlines", validatingcarrier: "5C" },
	{ companyName: "Cargojet Airways Ltd.", validatingcarrier: "W8" },
	{ companyName: "Cargolux S.A.", validatingcarrier: "CV" },
	{ companyName: "Caribbean Airlines", validatingcarrier: "BW" },
	{ companyName: "Carpatair", validatingcarrier: "V3" },
	{ companyName: "Cathay Pacific", validatingcarrier: "CX" },
	{ companyName: "CCM Airlines – Air Corsica", validatingcarrier: "XK" },
	{ companyName: "China Airlines", validatingcarrier: "CI" },
	{ companyName: "China Cargo Airlines Ltd.", validatingcarrier: "CK" },
	{ companyName: "China Eastern", validatingcarrier: "MU" },
	{ companyName: "China Southern Airlines", validatingcarrier: "CZ" },
	{ companyName: "Cimber Sterling A/S", validatingcarrier: "QI" },
	{ companyName: "Cirrus Airlines", validatingcarrier: "C9" },
	{ companyName: "CityJet", validatingcarrier: "WX" },
	{ companyName: "Comair", validatingcarrier: "MN" },
	{ companyName: "Condor", validatingcarrier: "DE" },
	{ companyName: "Condor Berlin", validatingcarrier: "None" },
	{ companyName: "Continental Airlines", validatingcarrier: "CO" },
	{ companyName: "Continental Micronesia", validatingcarrier: "CS" },
	{ companyName: "COPA Airlines", validatingcarrier: "CM" },
	{ companyName: "Corsair", validatingcarrier: "SS" },
	{ companyName: "Croatia Airlines", validatingcarrier: "OU" },
	{ companyName: "Cubana", validatingcarrier: "CU" },
	{ companyName: "Cyprus Airways", validatingcarrier: "CY" },
	{ companyName: "Czech Airlines", validatingcarrier: "OK" },
	{ companyName: "Delta Air Lines", validatingcarrier: "DL" },
	{ companyName: "DHL Air Ltd.", validatingcarrier: "D0" },
	{ companyName: "DHL International E.C.", validatingcarrier: "ES*" },
	{ companyName: "Donavia", validatingcarrier: "D9" },
	{ companyName: "Dragonair", validatingcarrier: "KA" },
	{ companyName: "Dubrovnik Airline", validatingcarrier: "2D" },
	{ companyName: "Egyptair", validatingcarrier: "MS" },
	{ companyName: "EL AL", validatingcarrier: "LY" },
	{ companyName: "Emirates", validatingcarrier: "EK" },
	{ companyName: "Estonian Air", validatingcarrier: "OV" },
	{ companyName: "Ethiopian Airlines", validatingcarrier: "ET" },
	{ companyName: "Etihad Airways", validatingcarrier: "EY" },
	{ companyName: "Euroatlantic Airways", validatingcarrier: "YU" },
	{ companyName: "Eurowings", validatingcarrier: "EW" },
	{ companyName: "EVA Air", validatingcarrier: "BR" },
	{ companyName: "Federal Express", validatingcarrier: "FX" },
	{ companyName: "Finnair", validatingcarrier: "AY" },
	{ companyName: "flybe", validatingcarrier: "BE" },
	{ companyName: "Freebird Airlines", validatingcarrier: "FH" },
	{ companyName: "Garuda", validatingcarrier: "GA" },
	{ companyName: "Georgian Airways", validatingcarrier: "A9" },
	{ companyName: "Gulf Air", validatingcarrier: "GF" },
	{ companyName: "Hahn Air", validatingcarrier: "HR" },
	{ companyName: "Hainan Airlines", validatingcarrier: "HU" },
	{ companyName: "Hawaiian Airlines", validatingcarrier: "HA" },
	{ companyName: "Hi Fly", validatingcarrier: "5K" },
	{ companyName: "Hong Kong Airlines", validatingcarrier: "HX" },
	{ companyName: "Hong Kong Air Cargo", validatingcarrier: "RH" },
	{ companyName: "IBERIA", validatingcarrier: "IB" },
	{ companyName: "Icelandair", validatingcarrier: "FI" },
	{ companyName: "Interair", validatingcarrier: "D6" },
	{ companyName: "Iran Air", validatingcarrier: "IR" },
	{ companyName: "Iran Aseman Airlines", validatingcarrier: "EP" },
	{ companyName: "Israir", validatingcarrier: "6H" },
	{ companyName: "Japan Airlines", validatingcarrier: "JL" },
	{ companyName: "Jat Airways", validatingcarrier: "JU" },
	{ companyName: "Jet Airways", validatingcarrier: "9W" },
	{ companyName: "Jet Lite (India) Limited", validatingcarrier: "S2" },
	{ companyName: "JetBlue", validatingcarrier: "B6" },
	{ companyName: "Jordan Aviation", validatingcarrier: "R5" },
	{ companyName: "Kenya Airways", validatingcarrier: "KQ" },
	{ companyName: "Kingfisher Airlines Ltd", validatingcarrier: "IT" },
	{ companyName: "Kish Air", validatingcarrier: "Y9" },
	{ companyName: "KLM", validatingcarrier: "KL" },
	{ companyName: "Korean Air", validatingcarrier: "KE" },
	{ companyName: "Kuwait Airways", validatingcarrier: "KU" },
	{ companyName: "LACSA", validatingcarrier: "LR" },
	{ companyName: "LAM", validatingcarrier: "TM" },
	{ companyName: "LATAM Airlines", validatingcarrier: "LA" },
	{ companyName: "LATAM Argentina", validatingcarrier: "4M" },
	{ companyName: "LATAM", validatingcarrier: "JJ" },
	{ companyName: "LATAM Cargo Chile", validatingcarrier: "UC" },
	{ companyName: "LATAM Peru", validatingcarrier: "LP" },
	{ companyName: "LATAM Ecuador", validatingcarrier: "XL" },
	{ companyName: "Lauda Air", validatingcarrier: "NG" },
	{ companyName: "Libyan Airlines", validatingcarrier: "LN" },
	{ companyName: "LOT Polish Airlines", validatingcarrier: "LO" },
	{ companyName: "LTU", validatingcarrier: "LT" },
	{ companyName: "Lufthansa", validatingcarrier: "LH" },
	{ companyName: "Lufthansa Cargo", validatingcarrier: "LH" },
	{ companyName: "Lufthansa CityLine", validatingcarrier: "CL" },
	{ companyName: "Luxair", validatingcarrier: "LG" },
	{ companyName: "Mahan Air", validatingcarrier: "W5" },
	{ companyName: "Malaysia Airlines", validatingcarrier: "MH" },
	{ companyName: "MALEV", validatingcarrier: "MA" },
	{ companyName: "Malmö Aviation", validatingcarrier: "TF" },
	{ companyName: "MAS AIR", validatingcarrier: "M7" },
	{ companyName: "MEA", validatingcarrier: "ME" },
	{ companyName: "Meridiana fly", validatingcarrier: "IG" },
	{ companyName: "Mexicana", validatingcarrier: "MX" },
	{ companyName: "MIAT", validatingcarrier: "OM" },
	{ companyName: "Montenegro Airlines", validatingcarrier: "YM" },
	{ companyName: "Nippon Cargo Airlines (NCA)", validatingcarrier: "KZ" },
	{ companyName: "Nouvelair", validatingcarrier: "BJ" },
	{ companyName: "Olympic Air", validatingcarrier: "OA" },
	{ companyName: "Oman Air", validatingcarrier: "WY" },
	{ companyName: "Onur Air", validatingcarrier: "8Q" },
	{ companyName: "PAL", validatingcarrier: "PR" },
	{ companyName: "Pegasus Airlines", validatingcarrier: "PC" },
	{ companyName: "PGA-Portugália Airlines", validatingcarrier: "NI" },
	{ companyName: "PIA", validatingcarrier: "PK" },
	{ companyName: "PLUNA", validatingcarrier: "PU" },
	{ companyName: "Precision Air", validatingcarrier: "PW" },
	{ companyName: "Qantas", validatingcarrier: "QF" },
	{ companyName: "Qatar Airways", validatingcarrier: "QR" },
	{ companyName: "Rossiya Airlines", validatingcarrier: "FV" },
	{ companyName: "Royal Air Maroc", validatingcarrier: "AT" },
	{ companyName: "Royal Brunei", validatingcarrier: "BI" },
	{ companyName: "Royal Jordanian", validatingcarrier: "RJ" },
	{ companyName: "SAA", validatingcarrier: "SA" },
	{ companyName: "Safair", validatingcarrier: "FA" },
	{ companyName: "SAS", validatingcarrier: "SK" },
	{ companyName: "SATA Air Açores", validatingcarrier: "SP" },
	{ companyName: "Saudi Arabian Airlines", validatingcarrier: "SV" },
	{ companyName: "Shandong Airlines Co., Ltd.", validatingcarrier: "SC" },
	{ companyName: "Shanghai Airlines", validatingcarrier: "FM" },
	{ companyName: "Shenzhen Airlines Co. Ltd.", validatingcarrier: "ZH" },
	{ companyName: "SIA", validatingcarrier: "SQ" },
	{ companyName: "SIA Cargo", validatingcarrier: "SQ" },
	{ companyName: "S7 Airlines", validatingcarrier: "S7" },
	{ companyName: "Sichuan Airlines Co. Ltd.", validatingcarrier: "3U" },
	{ companyName: "Silkair", validatingcarrier: "MI" },
	{ companyName: "SKY Airlines", validatingcarrier: "ZY" },
	{ companyName: "Skyways", validatingcarrier: "JZ" },
	{ companyName: "South African Express Airways", validatingcarrier: "XZ" },
	{ companyName: "Spanair", validatingcarrier: "JK" },
	{ companyName: "SriLankan", validatingcarrier: "UL" },
	{ companyName: "Sudan Airways", validatingcarrier: "SD" },
	{ companyName: "SunExpress", validatingcarrier: "XQ" },
	{ companyName: "Surinam Airways", validatingcarrier: "PY" },
	{ companyName: "SWISS", validatingcarrier: "LX" },
	{ companyName: "Syrianair", validatingcarrier: "RB" },
	{ companyName: "TAAG – Angola Airlines", validatingcarrier: "DT" },
	{ companyName: "TACA", validatingcarrier: "TA" },
	{ companyName: "TACA Peru", validatingcarrier: "T0" },
	{ companyName: "TACV Cabo Verde Airlines", validatingcarrier: "VR" },
	{
		companyName: "TAM – Transportes Aéreos del Mercosur ",
		validatingcarrier: "PZ",
	},
	{ companyName: "TAME – Linea Aérea del Ecuador", validatingcarrier: "EQ" },
	{ companyName: "TAP – Air Portugal", validatingcarrier: "TP" },
	{ companyName: "TAROM S.A.", validatingcarrier: "RO" },
	{ companyName: "Thai Airways International", validatingcarrier: "TG" },
	{ companyName: "THY – Turkish Airlines", validatingcarrier: "TK" },
	{ companyName: "TNT Airways S.A.", validatingcarrier: "3V" },
	{ companyName: "Transaero", validatingcarrier: "UN" },
	{ companyName: "TransAsia Airways", validatingcarrier: "GE" },
	{ companyName: "TUIfly", validatingcarrier: "X3" },
	{ companyName: "Tunis Air", validatingcarrier: "TU" },
	{ companyName: "Ukraine International Airlines", validatingcarrier: "PS" },
	{ companyName: "United Airlines", validatingcarrier: "UA" },
	{ companyName: "UPS Airlines", validatingcarrier: "5X" },
	{ companyName: "US Airways, Inc.", validatingcarrier: "US" },
	{ companyName: "UT air", validatingcarrier: "UT" },
	{ companyName: "Vietnam Airlines", validatingcarrier: "VN" },
	{ companyName: "Virgin Atlantic", validatingcarrier: "VS" },
	{ companyName: "Vladivostok Air", validatingcarrier: "XF" },
	{ companyName: "Volaris", validatingcarrier: "Y4" },
	{ companyName: "Volotea", validatingcarrier: "V7" },
	{ companyName: "Vueling", validatingcarrier: "VY" },
	{ companyName: "Volga-Dnepr Airlines", validatingcarrier: "VI" },
	{
		companyName: "Gol",
		validatingcarrier: "G3",
	},
	{ companyName: "Wataniya Airways", validatingcarrier: "KW" },
	{ companyName: "White Coloured by You", validatingcarrier: "" },
	{ companyName: "Wideroe", validatingcarrier: "WF" },
	{ companyName: "Xiamen Airlines", validatingcarrier: "MF" },
	{ companyName: "XL Airways", validatingcarrier: "SE" },
	{ companyName: "Yemenia", validatingcarrier: "IY" },
	{ companyName: "Boliviana de Aviación", validatingcarrier: "OB" },
	{ companyName: "Sky", validatingcarrier: "H2" },
];
