import React, { useState } from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { pt } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main css file
import './default.css'; // theme css file
import { addDays, addMonths, format } from 'date-fns';

import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white',
    },
    opacity: 1,
    '& .MuiInputBase-input': {
      color: `#FFF`,
    },
    '& label.Mui-focused': {
      color: `#FFF`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    '& .MuiFormLabel-root': {
      color: '#FFF',
      opacity: 0.45,
    },
    '& .MuiSelect-icon': {
      color: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&:hover fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      '&.Mui-focused fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&.Mui-error fieldset': {
        borderColor: '#FFF',
      },
    },
  },
}));

function InputCalendar({
  icon,
  label,
  onChange,
  value,
  error,
  helperText,
  minStartDays = 7,
}) {
  const classes = useStyles();

  const [date, setDate] = useState(null);

  const [open, setOpen] = useState(false);

  const setDatas = (data) => {
    let weekDayStartDate = format(data, 'EEEE dd MMMM yyyy', {
      locale: pt,
    });

    let startDate = data;

    let dataIda = format(data, 'dd/MM/yyyy');

    return {
      dataIda,
      weekDayStartDate,
      startDate,
    };
  };

  return (
			<>
				<Dialog
					onClose={() => setOpen(!open)}
					aria-labelledby='simple-dialog-title'
					open={open}
				>
					<S.DateCalendar
						onChange={(item) => {
							setDate(item);
							onChange(setDatas(item));
						}}
						locale={pt}
						date={date}
						minDate={addDays(new Date(), minStartDays)}
						maxDate={addMonths(new Date(), 11)}
					/>
					<Button onClick={(e) => setOpen(!open)}>OK</Button>
				</Dialog>
				<TextField
					variant='outlined'
					placeholder={label}
					readOnly
					label={label}
					value={date && setDatas(date).dataIda}
					onClick={() => setOpen(!open)}
					className={classes.root}
					fullWidth
					InputProps={
						icon && {
							startAdornment: (
								<InputAdornment position='start'>
									<img alt='icon' src={icon} />
								</InputAdornment>
							),
							readOnly: true,
						}
					}
					error={error}
					helperText={helperText}
				/>
			</>
		);
}

export default InputCalendar;
