import { Grid } from '@material-ui/core';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background-color: ${theme.palette.secondary.main};
    border-radius: 6px !important;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    font-family: 'Poppins';
    flex-wrap: wrap;
    margin-bottom: 35px;

    .title {
      padding-bottom: 8px;
      padding-left: 5px;
      border-bottom: 1px solid ${rgba('#707070', 0.21)};
      margin-bottom: 20px;
      width: 100%;
    }

    .title2 {
      margin-bottom: 10px;
    }
  `}
`;

export const CardContent = styled(Grid)`
  ${({ theme }) => css`
    padding: 15px;
    display: flex;
    width: 100%;
  `}
`;

export const CarDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    .car-picture {
      width: 120px;
      margin-top: 10px;
      margin-left: 5px;
      object-fit: contain;

      ${theme.breakpoints.down('xs')} {
        width: 40%;
      }
    }

    .car-details1 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-left: 20px;
      margin-bottom: 20px;

      ${theme.breakpoints.down('xs')} {
        width: 50%;
      }

      .company-logo {
        max-width: 65px;
        max-height: 50px;
        width: auto;
        height: auto;
        object-fit: contain;
      }

      .car-type {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
      }

      .car-model {
        width: 100%;
        font-size: 11px;
        font-weight: 500;
      }
    }

    .car-details2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ${theme.breakpoints.down('xs')} {
        margin-top: 15px;
      }

      div {
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 35px;

          span {
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            bottom: 0px;
            padding-top: 5px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  `}
`;

export const BottomDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    padding: 20px;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    .place-details {
      display: flex;
      width: 60%;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }

      div {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        padding-right: 20px;

        ${theme.breakpoints.down('xs')} {
          width: 100%;
          padding-right: 0;
        }

        span {
          width: 100%;
          font-size: 11px;

          ${theme.breakpoints.down('xs')} {
            &.place {
              margin-bottom: 15px;
            }
          }

          &.hour {
            font-weight: 200;
            padding-bottom: 10px;
          }
        }
      }
    }

    .reservation-details {
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
      div {
        display: flex;
        flex-wrap: wrap;

        ${theme.breakpoints.down('xs')} {
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          color: #4d7e4f;
          width: 100%;
        }
      }
    }
  `}
`;

export const SideContent = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid #e6e6e6;
    width: 20%;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      ${theme.breakpoints.down('xs')} {
        margin-bottom: 15px;

        div {
          display: flex;
          align-items: center;
        }
      }

      justify-content: center;

      .price-detail {
        width: 70%;
        border: 1px solid #e6e6e6;
        margin-top: 15px;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;

        ${theme.breakpoints.down('xs')} {
          border: none;
          width: 50%;
        }

        .total-price-less-discount {
          color: ${(props) => (props.discount === 0 ? '#000000' : '#4D7E4F')};
          font-size: 20px;
          font-weight: 700;
          display: flex;

          .cifrao {
            margin-top: 3px;
            font-size: 13px;
            margin-right: 3px;
            font-weight: normal;
          }
        }

        div {
          .discount {
            color: #4d7e4f;
            font-weight: 700;
          }

          .discount-value {
            padding-left: 10px;
            padding-right: 10px;
            text-decoration: line-through;
            opacity: 0.31;
          }
        }
      }

      .price-info {
        margin-top: 10px;
        flex-wrap: wrap;
        font-size: 11px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-content: center;
        height: auto;

        ${theme.breakpoints.up('xs')} {
          width: 50%;
        }

        span {
          width: 100%;
          text-align: center;

          ${theme.breakpoints.up('xs')} {
            text-align: left;
          }
        }
      }

      .button {
        ${theme.breakpoints.up('xs')} {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .subtitle {
      margin-left: 20px;
      font-size: 10px;
    }
  `}
`;
