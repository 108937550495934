import * as S from './styles';
import { Grid } from '@material-ui/core';

import ThemeButton from '../ThemeButton';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { pt } from 'react-date-range/dist/locale';
import { useGlobal } from '../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../utils/addFeeByDestinoFerias';
import { useState, useEffect } from 'react';
import RegisterModal from '../RegisterModal';

const ExpSubDetail = ({
	experience,
	experienceDate,
	value,
	totalValue,
	adults,
	childs,
	buscaAtual,
}) => {
	const { user, signed } = useGlobal();

	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);

	let history = useHistory();

	const onSubmit = () => {
		sessionStorage.setItem(
			'@ExperienceSelected',
			JSON.stringify({
				experienceDate: experienceDate,
				experience: experience,
				adults: parseInt(adults * experience.qtParticipants),
				childs: parseInt(childs),
			})
		);

		if (user?.guest) {
			return handleRegisterModal();
		}

		history.push('/busca/resumo');
	};

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<S.Wrapper>
			<Grid container spacing={2}>
				<S.GridFlexible item xs={12} lg={3}>
					<S.DateContainer>
						<S.Description>Data do ingresso</S.Description>
						<S.TotalValue>
							{experienceDate.date !== undefined &&
								experienceDate.date !== 'Invalid Date' &&
								format(parseISO(experienceDate.date), 'dd/MM/yyyy', {
									locale: pt,
								})}{' '}
							{experienceDate.time &&
								experienceDate.time !== null &&
								`${experienceDate.time.substring(0, 5)}`}
						</S.TotalValue>
					</S.DateContainer>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={6}>
					<S.ValueContainer>
						<S.Description>Valor unitário</S.Description>
						<S.TotalValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(totalValue)} {shortenCoinAlias}
								</span>
							) : (
								<span>
									{totalValue.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							)}
						</S.TotalValue>
					</S.ValueContainer>
					<S.ValueContainer>
						<S.Description>Valor Total</S.Description>
						<S.TotalValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(value)} {shortenCoinAlias}
								</span>
							) : (
								<span className='bold total'>
									{value.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							)}
						</S.TotalValue>
					</S.ValueContainer>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={3}>
					<S.PriceDetails>
						<ThemeButton onClick={() => onSubmit()} className='choose-hotel'>
							Escolher data
						</ThemeButton>
					</S.PriceDetails>
				</S.GridFlexible>
			</Grid>

			<RegisterModal open={isOpenRegisterModal} onClose={handleRegisterModal} />
		</S.Wrapper>
	);
};

export default ExpSubDetail;
