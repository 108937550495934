/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';

import VitrineRepository from '../../../repositories/vitrineRepository';
import ShowCaseLine from '../ShowCaseLine';

const ShowCase = ({ campaignId, user }) => {
  const vitrineRepository = new VitrineRepository();

  const [data, setData] = useState([]);

  const getShowCases = async () => {
    const res = await vitrineRepository.getShowCase();

    setData(res.data);
  };

  useEffect(() => {
    getShowCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
			data && data?.map((d) => <ShowCaseLine user={user} data={d} key={d.id} />)
		);
};

export default ShowCase;
