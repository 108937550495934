import React, { useEffect, useState } from 'react';
// import { Container } from '@material-ui/core';

import AirplanePrimary from '../../assets/icons/AirplanePrimary';
import CarPrimary from '../../assets/icons/CarPrimary';
import BedPrimary from '../../assets/icons/BedPrimary';
import BigWheelPrimary from '../..//assets/icons/BigWheelPrimary';
import DateRangeIcon from '@material-ui/icons/DateRange';
// import { useTheme } from '@material-ui/core';

import * as S from './styles';
import { Button } from '@material-ui/core';

import { Link } from 'react-router-dom';

export default function SubHeaderSearch({ searchState, buscaAtual }) {
  // const theme = useTheme();
  // const downOfSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [icon, setIcon] = useState('');

  useEffect(() => {
    switch (buscaAtual) {
      case 'Aéreo':
        setIcon(<AirplanePrimary />);
        break;
      case 'Carro':
        setIcon(<CarPrimary />);
        break;
      case 'Hotel':
        setIcon(<BedPrimary />);
        break;
      case 'Experiencias':
        setIcon(<BigWheelPrimary />);
        break;
      default:
        break;
    }
  }, [buscaAtual]);

  const BuscaAereo = ({
    departureAirport,
    arrivalAirport,
    departureDate,
    arrivalDate,
    idaVolta,
  }) => {
    return (
      <S.BuscaAereo>
        <span className='aeroporto-ida'>{departureAirport}</span>
        <S.Divider />
        <span className='aeroporto-volta'>{arrivalAirport}</span>
        <S.Divider />
        <span className='data-ida'>
          <DateRangeIcon />
          {departureDate}
        </span>
        <S.Divider />
        {idaVolta && (
          <>
            <span className='data-volta'>
              <DateRangeIcon />
              {arrivalDate}
            </span>
            <S.Divider />
          </>
        )}
        <Link to='/'>
          <Button>Editar</Button>
        </Link>
      </S.BuscaAereo>
    );
  };

  const BuscaCarro = ({
    pickUpLocation,
    dropOffLocation,
    pickUpDate,
    dropOffDate,
  }) => {
    return (
      <S.BuscaCarro>
        <div>
          <span className='aeroporto-ida'>{pickUpLocation}</span>
          <span className='aeroporto-volta'>{dropOffLocation}</span>
        </div>
        <S.Divider />
        <span className='data-ida'>
          <DateRangeIcon />
          {pickUpDate}
        </span>
        <S.Divider />
        <span className='data-volta'>
          <DateRangeIcon />
          {dropOffDate}
        </span>
        <S.Divider />
        <Link to='/'>
          <Button>Editar</Button>
        </Link>
      </S.BuscaCarro>
    );
  };

  const BuscaHotel = ({ location, checkin, checkout }) => {
    return (
      <S.BuscaHotel>
        <span className='location'>{location}</span>
        <S.Divider />
        <span className='checkin'>
          <DateRangeIcon />
          {checkin}
        </span>
        <S.Divider />
        <span className='checkout'>
          <DateRangeIcon />
          {checkout}
        </span>
        <S.Divider />
        <Link to='/'>
          <Button>Editar</Button>
        </Link>
      </S.BuscaHotel>
    );
  };

  const BuscaExperiencia = ({ location, checkin, checkout }) => {
    return (
      <S.BuscaHotel>
        <span className='location'>{location}</span>
        <S.Divider />
        <span className='checkin'>
          <DateRangeIcon />
          {checkin}
        </span>
        <S.Divider />
        <span className='checkout'>
          <DateRangeIcon />
          {checkout}
        </span>
        <S.Divider />
        <Link to='/'>
          <Button>Editar</Button>
        </Link>
      </S.BuscaHotel>
    );
  };

  return (
			<S.Wrapper>
				<S.Toolbar buscaAtual={buscaAtual}>
					<div className='type-container'>
						{icon}
						<span>{buscaAtual !== 'Experiencias' ? buscaAtual : 'Experiências'}</span>
					</div>
					<div className='triangle' />
					<div className='content'>
						{buscaAtual === 'Aéreo' && searchState !== undefined && (
							<BuscaAereo
								departureAirport={searchState.origem.airportName}
								arrivalAirport={searchState.destino.airportName}
								departureDate={searchState.datas.dataIda}
								arrivalDate={searchState.datas.dataVolta}
								idaVolta={searchState.idavolta}
							/>
						)}

						{buscaAtual === 'Carro' && searchState !== undefined && (
							<BuscaCarro
								pickUpLocation={
									searchState.localRetiradaName
										? searchState.localRetiradaName
										: `${searchState.localRetirada.rental.name} - ${searchState.localRetirada.address.address}, ${searchState.localRetirada.address.cityName}`
								}
								dropOffLocation={
									searchState.localRetiradaName
										? searchState.localRetiradaName
										: searchState.mesmoLocal
										? `${searchState.localRetirada.rental.name} - ${searchState.localRetirada.address.address}, ${searchState.localRetirada.address.cityName}`
										: `${searchState.localDevolucao.rental.name} - ${searchState.localDevolucao.address.address}, ${searchState.localDevolucao.address.cityName}`
								}
								pickUpDate={searchState.datas.dataIda}
								dropOffDate={searchState.datas.dataVolta}
							/>
						)}

						{buscaAtual === 'Hotel' && searchState !== undefined && (
							<BuscaHotel
								location={`${searchState.cidade.cityName}`}
								checkin={searchState.datas.weekDayStartDate}
								checkout={searchState.datas.weekDayEndDate}
							/>
						)}

						{buscaAtual === 'Experiencias' && searchState !== undefined && (
							<BuscaExperiencia
								location={`${searchState.cidade.cityName}${
									searchState.cidade.stateName ? `, ${searchState.cidade.stateName}` : ''
								}`}
								checkin={searchState.datas.weekDayStartDate}
								checkout={searchState.datas.weekDayEndDate}
							/>
						)}
					</div>
				</S.Toolbar>
			</S.Wrapper>
		);
}
