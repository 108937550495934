import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .image {
      height: 400px;
      object-fit: cover !important;

      &.card {
        height: 200px;
        width: 100%;
        object-fit: cover !important;
        border-top-left-radius: 6px;
      }

      &.card-room {
        width: 180px;
        height: 150px;
        object-fit: cover !important;
        border-radius: 6px;
      }
    }
  `}
`;
