import React, { useEffect, useState } from "react";
import * as S from "./styles";

import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import CarouselComponent from "../Carousel";

import GradeIcon from "@material-ui/icons/Grade";
import PlaceIcon from "@material-ui/icons/Place";
import CheckIcon from "@material-ui/icons/Check";

import Room from "../Room";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
// import { format, parseISO } from 'date-fns';
// import { pt } from 'date-fns/locale';
// import { getHotelDetails } from '../../repositories/getHotels';

import { getSessionStorage } from "../../utils/getSessionStorage";

function HotelRoomDetails({ searchState, buscaAtual }) {
	const [hotel, setHotel] = useState();
	// const [hotelDetails, setHotelDetails] = useState();

	const nights = (new Date(hotel?.availability?.end) - new Date(hotel?.availability?.start)) / (1000 * 3600 * 24);

	useEffect(() => {
		setHotel(getSessionStorage('@hotelSelected'));
	}, []);

	const stars = hotel
		? Array(Math.round(hotel.basicPropertyInfo?.award?.rating))
				.fill()
				.map((_, idx) => hotel.basicPropertyInfo?.award?.rating + idx)
		: [];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<S.Wrapper>
			<Container>
				<Grid container>
					<Grid item xs={12} md={12} lg={8}>
						<S.CarouselContainer>
							{/* {hotel?.hotelDescriptiveContent?.hotelInfo.descriptions
                .multimediaDescriptionsType.multimediaDescriptions[0]
                .imageItemsType?.imageItems &&
                 ( */}
							<CarouselComponent
								images={
									hotel?.hotelDescriptiveContent.hotelInfo.descriptions.multimediaDescriptionsType.multimediaDescriptions[0].imageItemsType?.imageItems?.map(
										(img) => img.url.address
									) || [hotel?.basicPropertyInfo.imageURL]
								}
							/>
							{/* )} */}
							{/* <CarouselComponent images={[hotel?.basicPropertyInfo.imageURL]} /> */}
						</S.CarouselContainer>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<S.HotelName>
							<span>
								<S.Title>{hotel?.basicPropertyInfo.hotelRef.hotelName}</S.Title>
							</span>
							{stars.map((star, index) => (
								<GradeIcon fontSize="small" key={index} />
							))}
							{hotel?.basicPropertyInfo.address.addressLine && (
								<span className="location">
									<PlaceIcon fontSize="small" />
									<S.PlaceLink
										target="_blank"
										rel="noreferrer"
										href={`https://www.google.com.br/maps/place/${hotel?.basicPropertyInfo.address.addressLine.replaceAll(" ", "+")}`}
									>
										{hotel?.basicPropertyInfo.address.addressLine}
									</S.PlaceLink>
								</span>
							)}
						</S.HotelName>
						<S.InformacoesUteis>
							<S.Title3>Informações úteis</S.Title3>
							<S.CheckInOut>
								<strong>Check-in: </strong>

								<span>
									{hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.start !== undefined &&
										hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.start !== "Invalid Date" &&
										format(parseISO(hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.start), "HH:mm", {
											locale: pt,
										})}
									h
								</span>
							</S.CheckInOut>
							<S.CheckInOut>
								<strong>Check-out: </strong>
								<span>
									{hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.end !== undefined &&
										hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.end !== "Invalid Date" &&
										format(parseISO(hotel?.hotelDescriptiveContent.hotelInfo.checkInHours?.end), "HH:mm", {
											locale: pt,
										})}
									h
								</span>
							</S.CheckInOut>
						</S.InformacoesUteis>
					</Grid>
					<Grid item xs={12}>
						<S.Title3>Descrição</S.Title3>
						<S.Descricao
							dangerouslySetInnerHTML={{
								__html: hotel?.hotelDescriptiveContent?.hotelInfo?.descriptions.descriptiveText,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<S.Title3>Quartos disponíveis</S.Title3>
						{hotel?.roomStays.roomTypes &&
							hotel.roomStays.roomTypes
								.sort((a, b) => {
									if (
										a.roomRates[0]?.[a.roomRates[0].total.currencyCode === "BRL" ? "total" : "origTotal" || "total"].amountAfterTax >
										b.roomRates[0]?.[b.roomRates[0].total.currencyCode === "BRL" ? "total" : "origTotal" || "total"].amountAfterTax
									) {
										return 1;
									}
									if (
										a.roomRates[0]?.[a.roomRates[0].total.currencyCode === "BRL" ? "total" : "origTotal" || "total"].amountAfterTax <
										b.roomRates[0]?.[b.roomRates[0].total.currencyCode === "BRL" ? "total" : "origTotal" || "total"].amountAfterTax
									) {
										return -1;
									}
									return 0;
								})
								.map((room) => {
									// se o quarto não tiver nenhum tipo de tarifa, não mostrar
									return (
										<Room
											searchState={searchState}
											roomName={room.roomName}
											roomRates={room.roomRates}
											nights={nights}
											hotel={hotel}
											room={room}
											buscaAtual={buscaAtual}
										/>
									);
									// : (
									//   <></>
									// );
								})}
					</Grid>
					{(hotel?.basicPropertyInfo?.address?.addressLine || hotel?.basicPropertyInfo?.position) && (
						<Grid item xs={12} lg={12}>
							<div>
								<iframe
									width="100%"
									height="450"
									frameborder="0"
									title="map"
									src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAC58QTaHYJqtPPW2COx4a7f6PXNeU0DUU&q=${
										hotel?.basicPropertyInfo?.address?.addressLine
											? hotel?.basicPropertyInfo?.address?.addressLine?.replaceAll(" ", "+")
											: `${hotel?.basicPropertyInfo?.position?.latitude},${hotel?.basicPropertyInfo?.position?.longitude}`
									}`}
									allowfullscreen
								></iframe>
							</div>
						</Grid>
					)}
					<Grid item xs={12} lg={12}>
						<S.Title3>Comodidades do hotel</S.Title3>

						<S.Amenities>
							{hotel?.hotelDescriptiveContent?.hotelInfo?.hotelAmenities?.map((amenitie) => (
								// {hotelDetails?.hotelInfo?.hotelAmenities?.map((amenitie) => (
								<span>
									<CheckIcon fontSize="small" />
									{amenitie.hotelAmenity}
								</span>
							))}
						</S.Amenities>
					</Grid>
				</Grid>
			</Container>
		</S.Wrapper>
	);
}

export default withRouter(HotelRoomDetails);
