import { useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/globalContext';
import { useHistory, withRouter } from 'react-router';
import GlobalLoader from '../../components/GlobalLoader';
import { useCampaign } from '../../hooks/useCampaign';

function Guest() {
	const { loginGuestByCampaignCode, signed } = useGlobal();
	let history = useHistory();
	const { campaignId, campaignCode, getCampaignColors } = useCampaign();
	const [loadingConfig, setLoadingConfig] = useState(false);

	useEffect(() => {
		if (signed) {
			history.push('/');
		}
	}, [signed, history]);

	useEffect(() => {
		const loadConfig = async () => {
			await getCampaignColors(true);
			setLoadingConfig(false);
		};

		if (campaignId && campaignCode) {
			loadConfig();
		}
	}, [campaignId, campaignCode, getCampaignColors]);

	useEffect(() => {
		if (campaignId && campaignCode && !loadingConfig) {
			loginGuestByCampaignCode(campaignCode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<GlobalLoader />
		</>
	);
}
export default withRouter(Guest);
