import React, { useState } from 'react';
import * as S from './styles';

import { withRouter } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import CarouselComponent from '../Carousel';

// import GradeIcon from '@material-ui/icons/Grade';
import PlaceIcon from '@material-ui/icons/Place';
import CheckIcon from '@material-ui/icons/Check';

// import ThemeButton from '../ThemeButton';
import FormInput from '../FormInput';
import ExpSubDetail from '../ExpSubDetail';

function ExperienceDetailsComponent({ experience, images, buscaAtual }) {
  const [adults, setAdults] = useState(1);

  return (
    <S.Wrapper>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12} lg={8}>
            <S.CarouselContainer>
              <CarouselComponent images={images} />
            </S.CarouselContainer>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <S.ExperienceName>
              <span>
                <S.Title>{experience?.name}</S.Title>
              </span>
              {/* {stars.map((star, index) => (
                <GradeIcon fontSize='small' key={index} />
              ))} */}
              <span className='location'>
                <PlaceIcon fontSize='small' />
                {experience?.localization}
              </span>
            </S.ExperienceName>
            <S.InformacoesUteis>
              <S.Title3>Selecione o número de Ingressos</S.Title3>
              <Typography variant='span'>
                <b>Atenção</b>, o valor de cada ingresso é valido para{' '}
                {experience?.qtParticipants} pessoa(s)
              </Typography>
              {/* <S.CheckInOut>
                <strong>Check-in: </strong>
                {experience?.startDate !== undefined &&
                  experience?.startDate !== 'Invalid Date' &&
                  format(parseISO(experience?.startDate), 'dd/MM/yyyy HH:mm', {
                    locale: pt,
                  })}
                h
              </S.CheckInOut>
              <S.CheckInOut>
                <strong>Check-out: </strong>
                <span>
                  {experience?.endDate !== undefined &&
                    experience?.endDate !== 'Invalid Date' &&
                    format(parseISO(experience?.endDate), 'dd/MM/yyyy HH:mm', {
                      locale: pt,
                    })}
                  h
                </span>
              </S.CheckInOut> */}
              <S.Personas>
                <FormInput
                  type='number'
                  label='Ingressos'
                  value={adults}
                  onChange={(e) =>
                    e.target.value >= 0 && setAdults(e.target.value)
                  }
                />
                {/* <FormInput
                  type='number'
                  label='Crianças'
                  value={childs}
                  onChange={(e) =>
                    e.target.value >= 0 && setChilds(e.target.value)
                  } 
                />*/}
              </S.Personas>
              {/* {parseFloat(adults) + parseFloat(childs) === 0 && (
                <Typography variant='span' color='error'>
                  Por favor, selecione ao menos uma pessoa
                </Typography>
              )} */}
              <S.ValueInfos>
                {/* <div>
                  {experience?.originalPrice > 0 && (
                    <span className='old-value'>
                      De{' '}
                      <CurrencyFormat
                        fixedDecimalScale={true}
                        className='value'
                        value={
                          experience?.originalPrice *
                          (parseFloat(adults) + parseFloat(childs))
                        }
                        displayType={'text'}
                        decimalSeparator=','
                        decimalScale={2}
                        prefix={'R$ '}
                      />{' '}
                      por{' '}
                    </span>
                  )}
                  <span className='final-value'>
                    <CurrencyFormat
                      fixedDecimalScale={true}
                      value={
                        experience?.price *
                        (parseFloat(adults) + parseFloat(childs))
                      }
                      displayType={'text'}
                      decimalSeparator=','
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </span>
                </div> */}
                {/* <ThemeButton
                  onClick={() => history.push('/busca/resumo')}
                  disabled={
                    parseFloat(adults) + parseFloat(childs) === 0 ? true : false
                  }
                  className='choose-hotel'
                >
                  Comprar
                </ThemeButton> */}
              </S.ValueInfos>
            </S.InformacoesUteis>
          </Grid>
          <Grid item xs={12}>
            <S.Title3>Descrição</S.Title3>
            <S.Descricao>
              {experience?.description}. {experience?.whatToExpect}
            </S.Descricao>
          </Grid>

          <Grid item xs={12}>
            <S.Title3>Datas disponíveis</S.Title3>
            {experience?.experienceDates.map((experienceDate) => (
              <ExpSubDetail
                value={experience?.price * parseFloat(adults)}
                experience={experience}
                adults={adults}
                // childs={childs}
                totalValue={experience?.price}
                experienceDate={experienceDate}
                buscaAtual={buscaAtual}
              />
            ))}
          </Grid>

          <Grid item xs={12} lg={12}>
            <S.Title3>Outras informações</S.Title3>

            <S.Amenities>
              {experience?.whatIsIncluded && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>O que está incluso?</b>
                  </span>
                  <span className='child'>{experience.whatIsIncluded}</span>
                </>
              )}
              {experience?.whatIsNotIncluded && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>O que não está incluso?</b>
                  </span>
                  <span className='child'>{experience.whatIsNotIncluded}</span>
                </>
              )}
              {experience?.category && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>Categoria</b>
                  </span>
                  <span className='child'>{experience.category}</span>
                </>
              )}
              {experience?.freeCancel && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>Cancelamento Gratis?</b>
                  </span>
                  <span className='child'>Sim</span>
                </>
              )}
              {experience?.cancellationPolicy !== '' && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>Política de cancelamento</b>
                  </span>
                  <span className='child'>
                    {experience?.cancellationPolicy}
                  </span>
                </>
              )}
              {experience?.importantInfo !== '' && (
                <>
                  <span>
                    <CheckIcon fontSize='small' />
                    <b>Informações importantes</b>
                  </span>
                  <span className='child'>{experience?.importantInfo}</span>
                </>
              )}
            </S.Amenities>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  );
}

export default withRouter(ExperienceDetailsComponent);
