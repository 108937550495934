import { Toolbar as MuiToolbar, Divider as MuiDivider } from "@material-ui/core";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

export const Wrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 55px;
		background-color: ${rgba(campaignColors.searchHeaderColor ? campaignColors.searchHeaderColor : theme.palette.secondary.main, 0.8)};
		display: flex;
		font-family: "Poppins";
		margin-bottom: 12px;
		${theme.breakpoints.down("sm")} {
			height: 120px;
		}
	`}
`;

export const Toolbar = styled(MuiToolbar)`
	${({ theme }) => css`
		width: 100%;
		margin-top: 0px;
		min-height: 0px;
		padding: 0px;

		.type-container {
			margin-top: 0px;
			padding: 0px;
			background-color: ${rgba(
				campaignColors.searchHeaderColor
					? campaignColors.searchHeaderColor
					: theme.palette.secondary.main,
				0.9
			)};
			height: 55px;
			width: 210px;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;

			svg {
				width: 30px;
				opacity: 100%;
				stroke: ${(props) =>
					props.buscaAtual === 'Hotel'
						? campaignColors.homeTextColor
							? campaignColors.homeTextColor
							: theme.palette.secondary.white
						: 'transparent'};
				path {
					fill: ${(props) =>
						props.buscaAtual === 'Hotel'
							? 'transparent'
							: campaignColors.homeTextColor
							? campaignColors.homeTextColor
							: theme.palette.secondary.white};
				}
			}

			span {
				padding-left: 10px;
				display: inline-block;
				align-self: center;
				color: ${campaignColors.homeTextColor
					? campaignColors.homeTextColor
					: theme.palette.secondary.white};
				font-weight: 500;
				font-size: 16px;
			}

			${theme.breakpoints.down('sm')} {
				height: 100%;
				flex-wrap: wrap;
			}

			${theme.breakpoints.down('xs')} {
				span {
					display: none;
				}
			}
		}

		.triangle {
			border-top: 27.5px solid transparent;
			border-bottom: 27.5px solid transparent;
			border-left: 70px solid
				${rgba(
					campaignColors.searchHeaderColor
						? campaignColors.searchHeaderColor
						: theme.palette.secondary.main,
					0.9
				)};

			${theme.breakpoints.down('sm')} {
				display: none;
			}
		}

		.content {
			display: flex;
		}
	`}
`;

export const BuscaAereo = styled.div`
	${({ theme }) => css`
		margin-left: 10px;
		font-size: 13px;
		display: inherit;
		align-items: center;

		${theme.breakpoints.down("sm")} {
			flex-direction: row;
			flex-wrap: wrap;
		}

		span {
			display: inline-block;
			color: ${rgba("#000", 0.52)};
			vertical-align: middle;

			${theme.breakpoints.down("sm")} {
				font-size: 12px;

				&.aeroporto-ida,
				&.aeroporto-volta {
					width: 50%;
					margin-bottom: 10px;
				}

				&.data-ida,
				&.data-volta {
					width: 50%;
					margin-bottom: 10px;
				}

				${theme.breakpoints.down("xs")} {
					&.aeroporto-ida,
					&.aeroporto-volta {
						width: 100%;
					}

					&.data-ida,
					&.data-volta {
						width: 50%;
					}
				}
			}
		}

		svg {
			width: 17px;
			vertical-align: middle;
			margin-top: -3px;
			margin-right: 5px;
		}

		a {
			text-decoration: none;

			${theme.breakpoints.down("sm")} {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
		}

		.MuiButton-label {
			align-self: flex-end;
			text-transform: capitalize;
		}
	`}
`;

export const BuscaCarro = styled.div`
	${({ theme }) => css`
		margin-left: 10px;
		font-size: 13px;
		display: inherit;
		align-items: center;

		${theme.breakpoints.down("sm")} {
			flex-direction: row;
			flex-wrap: wrap;
		}

		div {
			${theme.breakpoints.up("md")} {
				display: flex;
				flex-direction: column;
			}
		}

		span {
			display: inline-block;
			color: ${rgba("#000", 0.52)};
			vertical-align: middle;

			${theme.breakpoints.down("sm")} {
				font-size: 12px;

				&.aeroporto-ida,
				&.aeroporto-volta {
					width: 50%;
					margin-bottom: 10px;
				}

				&.data-ida,
				&.data-volta {
					width: 50%;
					margin-bottom: 10px;
				}

				${theme.breakpoints.down("xs")} {
					&.aeroporto-ida,
					&.aeroporto-volta {
						width: 100%;
					}

					&.data-ida,
					&.data-volta {
						width: 50%;
					}
				}
			}
		}

		svg {
			width: 17px;
			vertical-align: middle;
			margin-top: -3px;
			margin-right: 5px;
		}

		a {
			text-decoration: none;

			${theme.breakpoints.down("sm")} {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
		}

		.MuiButton-label {
			align-self: flex-end;
			text-transform: capitalize;
		}
	`}
`;

export const BuscaHotel = styled.div`
	${({ theme }) => css`
		margin-left: 10px;
		font-size: 13px;
		display: inherit;
		align-items: center;

		${theme.breakpoints.down("sm")} {
			flex-direction: row;
			flex-wrap: wrap;
		}

		span {
			display: inline-block;
			color: ${rgba("#000", 0.52)};
			vertical-align: middle;

			&.checkin,
			&.checkout {
				text-transform: capitalize;
			}

			${theme.breakpoints.down("sm")} {
				font-size: 12px;

				&.location {
					width: 100%;
					margin-bottom: 10px;
					padding-top: 10px;
				}

				&.checkin,
				&.checkout {
					width: 100%;
					margin-bottom: 10px;
				}

				${theme.breakpoints.down("xs")} {
					&.location {
						width: 100%;
					}

					&.checkin,
					&.checkout {
						width: 100%;
					}
				}
			}
		}

		svg {
			width: 17px;
			vertical-align: middle;
			margin-top: -3px;
			margin-right: 5px;
		}

		a {
			text-decoration: none;

			${theme.breakpoints.down("sm")} {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
		}

		.MuiButton-label {
			align-self: flex-end;
			text-transform: capitalize;
		}
	`}
`;

export const Divider = styled(MuiDivider)`
	${({ theme }) => css`
		width: 1px;
		height: 30px;
		background-color: ${rgba("#000", 0.32)};
		margin-left: 15px;
		margin-right: 15px;

		${theme.breakpoints.down("sm")} {
			display: none;
		}
	`}
`;
