import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';

import * as S from './styles';

import UserRepository from '../../repositories/userRepository';
import { Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
});

function ModalPointsBalance({ open, onClose, coinAlias }) {
	const classes = useStyles();

	const userRepository = new UserRepository();
	const [state, setState] = useState();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const getPoints = async () => {
		const balance = await userRepository.getUserPointsBalance();

		if (balance.data) {
			const rows = balance.data.map((item) =>
				createData(item.date, item.description, item.action, item.pointsValue)
			);

			setState(rows);
		}
	};

	function createData(date, description, action, pointsValue) {
		date = format(new Date(date), 'dd/MM/yyyy');
		return { date, description, action, pointsValue };
	}

	const columns = [
		{ id: 'date', label: 'Data' },
		{ id: 'description', label: 'Descrição' },
		{ id: 'action', label: 'Ação' },
		{ id: 'pointsValue', label: coinAlias, align: 'right' },
	];

	useEffect(() => {
		if (open) {
			getPoints();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<S.Modal>
			<Dialog
				open={open}
				// onClose={() => onClose(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='customized-dialog-title' onClose={() => onClose(false)}>
					Extrato de {coinAlias}
				</DialogTitle>
				<DialogContent dividers>
					<Paper className={classes.root}>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label='sticky table'>
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{state
										?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										?.map((row) => {
											return (
												<TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
													{columns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell key={column.id} align={column.align}>
																<Typography
																	style={{
																		fontSize: '0.9rem',
																		lineHeight: 1,
																		color:
																			column.id === 'pointsValue'
																				? value < 0
																					? 'red'
																					: 'green'
																				: 'initial',
																	}}
																>
																	{column.format && typeof value === 'number'
																		? column.format(value)
																		: value}
																</Typography>
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component='div'
							count={state?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage='Linhas por página'
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
						/>
					</Paper>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							onClose();
						}}
						color='primary'
					>
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</S.Modal>
	);
}

export default ModalPointsBalance;
