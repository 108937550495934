import React, { useEffect, useState } from "react";
import * as S from "./styles";

import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import CarouselComponent from "../Carousel";

import GradeIcon from "@material-ui/icons/Grade";
import PlaceIcon from "@material-ui/icons/Place";
import CheckIcon from "@material-ui/icons/Check";

import RoomOld from "../RoomOld";
import { getHotelDetails } from "../../repositories/getHotels";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { pt } from "date-fns/locale";

import { getSessionStorage } from "../../utils/getSessionStorage";

function HotelRoomDetailsOld({ searchState, buscaAtual }) {
	const [hotel, setHotel] = useState();
	const [hotelDetails, setHotelDetails] = useState();

	const nights = (new Date(hotel?.price.end) - new Date(hotel?.price.start)) / (1000 * 3600 * 24);

	useEffect(() => {
		hotel &&
			getHotelDetails(hotel?.basicPropertyInfo.hotelRef.hotelCode)
				.then((res) => {
					setHotelDetails(res.data);
				})
				.catch((error) => {});
	}, [hotel]);

	useEffect(() => {
		setHotel(getSessionStorage('@hotelSelected'));
	}, []);

	const stars = hotel
		? Array(hotel.basicPropertyInfo?.award?.rating)
				.fill()
				.map((_, idx) => hotel.basicPropertyInfo?.award?.rating + idx)
		: [];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<S.Wrapper>
			<Container>
				<Grid container>
					<Grid item xs={12} md={12} lg={8}>
						<S.CarouselContainer>
							<CarouselComponent images={[hotel?.basicPropertyInfo.imageURL]} />
						</S.CarouselContainer>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<S.HotelName>
							<span>
								<S.Title>{hotel?.basicPropertyInfo.hotelRef.hotelName}</S.Title>
							</span>
							{stars.map((star, index) => (
								<GradeIcon fontSize="small" key={index} />
							))}
							<span className="location">
								<PlaceIcon fontSize="small" />
								<S.PlaceLink
									target="_blank"
									rel="noreferrer"
									href={`https://www.google.com.br/maps/place/${hotel?.basicPropertyInfo.address.addressLine.replaceAll(" ", "+")}`}
								>
									{hotel?.basicPropertyInfo.address.addressLine}
								</S.PlaceLink>
							</span>
						</S.HotelName>
						<S.InformacoesUteis>
							<S.Title3>Informações úteis</S.Title3>
							<S.CheckInOut>
								<strong>Check-in: </strong>

								<span>
									{hotelDetails?.hotelInfo?.checkInHours.start !== undefined &&
										hotelDetails?.hotelInfo?.checkInHours.start !== "Invalid Date" &&
										format(parseISO(hotelDetails?.hotelInfo?.checkInHours.start), "HH:mm", {
											locale: pt,
										})}
									h
								</span>
							</S.CheckInOut>
							<S.CheckInOut>
								<strong>Check-in: </strong>
								<span>
									{hotelDetails?.hotelInfo?.checkInHours.end !== undefined &&
										hotelDetails?.hotelInfo?.checkInHours.end !== "Invalid Date" &&
										format(parseISO(hotelDetails?.hotelInfo?.checkInHours.end), "HH:mm", {
											locale: pt,
										})}
									h
								</span>
							</S.CheckInOut>
						</S.InformacoesUteis>
					</Grid>
					<Grid item xs={12}>
						<S.Title3>Descrição</S.Title3>
						<S.Descricao>{hotelDetails?.hotelInfo?.descriptions.descriptiveText}</S.Descricao>
					</Grid>
					<Grid item xs={12}>
						<S.Title3>Quartos disponíveis</S.Title3>
						{hotel?.roomStays.roomTypes &&
							hotel.roomStays.roomTypes
								.sort((a, b) => {
									if (a.roomRates[0]?.total.amountAfterTax > b.roomRates[0]?.total.amountAfterTax) {
										return 1;
									}
									if (a.roomRates[0]?.total.amountAfterTax < b.roomRates[0]?.total.amountAfterTax) {
										return -1;
									}
									return 0;
								})
								.map((room) => (
									<RoomOld
										searchState={searchState}
										roomName={room.roomName}
										images={[hotel.basicPropertyInfo.imageURL]}
										description={room.roomDescription.description}
										roomRates={room.roomRates}
										nights={nights}
										roomDetails={hotelDetails?.guestRooms.find((r) => r.id === room.roomID)}
										hotel={hotel}
										hotelDetails={hotelDetails}
										room={room}
										buscaAtual={buscaAtual}
									/>
								))}
					</Grid>
					<Grid item xs={12} lg={12}>
						<div>
							<iframe
								width="100%"
								height="450"
								frameborder="0"
								title="map"
								src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAC58QTaHYJqtPPW2COx4a7f6PXNeU0DUU&q=${hotel?.basicPropertyInfo?.address?.addressLine?.replaceAll(
									" ",
									"+"
								)}`}
								allowfullscreen
							></iframe>
						</div>
					</Grid>
					<Grid item xs={12} lg={12}>
						<S.Title3>Comodidades do hotel</S.Title3>

						<S.Amenities>
							{hotelDetails?.hotelInfo?.hotelAmenities?.map((amenitie) => (
								<span>
									<CheckIcon fontSize="small" />
									{amenitie.hotelAmenity}
								</span>
							))}
						</S.Amenities>
					</Grid>
				</Grid>
			</Container>
		</S.Wrapper>
	);
}

export default withRouter(HotelRoomDetailsOld);
