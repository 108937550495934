/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@material-ui/core";
import { Drawer, Link, Menu, MenuItem, Button, useTheme, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import * as S from "./styles";
import { useGlobal } from "../../contexts/globalContext";
import { getLogos } from "../../utils/getLogos";
import { getCampaignColors } from "../../utils/getCampaignColors";
import ModalPointsBalance from '../ModalPointsBalance';

import { getSessionStorage } from '../../utils/getSessionStorage';

export default function Header({ type }) {
	const [openModalPointsBalance, setOpenModalPointsBalance] = useState(false);
	const campaignColors = getCampaignColors();

	let history = useHistory();
	const { signed, user, logout, loadingPointsBalance, setLoadingPointsBalance } =
		useGlobal();

	const startHeaderData = {
		label: 'Início',
		href: '/',
	};

	const [headersData, setHeadersData] = useState([startHeaderData]);

	const [activeButton, setActiveButton] = useState('/');
	const [campaignId, setCampaignId] = useState(0);
	const [coinAlias, setCoinAlias] = useState('Pontos');

	useEffect(() => {
		let idCampanha = getSessionStorage('campaign');

		if (idCampanha === null) idCampanha = user?.campaign?.campaignCode;

		idCampanha && setCampaignId(idCampanha);
	}, [user?.campaign?.campaignCode]);

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || 'Pontos');
	}, [user?.campaign?.campaignConfig?.coinAlias]);

	useEffect(() => {
		const headers = user?.campaign?.campaignConfigLayout?.headers?.map((menu) => {
			return { label: menu.name, href: menu.link };
		});

		headers?.splice(0, 0, startHeaderData);
		if (headers?.length > 0) {
			//setHeadersData([...headersData, ...headers]);
			setHeadersData(headers);
		}
	}, [user?.campaign?.campaignConfigLayout?.headers]);

	const theme = useTheme();
	const downOfSm = useMediaQuery(theme.breakpoints.down('sm'));

	const [state, setState] = useState({
		mobileView: false,
		drawerOpen: false,
	});

	const { mobileView, drawerOpen } = state;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const setResponsiveness = () => {
			return !downOfSm
				? setState((prevState) => ({ ...prevState, mobileView: false }))
				: setState((prevState) => ({ ...prevState, mobileView: true }));
		};

		setResponsiveness();

		window.addEventListener('resize', () => setResponsiveness());

		return () => {
			window.removeEventListener('resize', () => setResponsiveness());
		};
	}, [downOfSm]);

	useEffect(() => {
		if (signed) setLoadingPointsBalance(false);
	}, [setLoadingPointsBalance, signed]);

	const displayDesktop = () => {
		return (
			<S.Toolbar>
				{type === 'home' ? mainLogo : mainLogoMaterial}

				{getMenuButtons()}
				{campaignId !== 0 && <S.Divider orientation='vertical' />}

				{campaignId !== 0 ? (
					!signed ? (
						type === 'home' ? (
							<S.MenuButtonDesktopHome
								{...{
									color: 'inherit',
									to: '/login',
									component: RouterLink,
								}}
							>
								{loadingPointsBalance ? 'carregando...' : 'Login'}
							</S.MenuButtonDesktopHome>
						) : (
							<S.MenuButtonDesktop
								{...{
									color: 'inherit',
									to: '/login',
									component: RouterLink,
								}}
							>
								{loadingPointsBalance ? 'carregando...' : 'Login'}
							</S.MenuButtonDesktop>
						)
					) : (
						<S.UserSignedInfo onClick={handleClick}>
							<span className='HeaderUserSigned'>{user.name}</span>
							{!user?.guest && !user?.campaign?.disablePointActivity && (
								<div className='HeaderSaldoPontos'>
									<label>Saldo</label>
									<span>{user?.pointsBalance?.toLocaleString('pt-BR') || 0}</span>
									<span className='pontos'> {coinAlias}</span>
								</div>
							)}
						</S.UserSignedInfo>
					)
				) : (
					<></>
				)}
			</S.Toolbar>
		);
	};

	const displayMobile = () => {
		const handleDrawerOpen = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: true }));
		const handleDrawerClose = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: false }));

		return (
			<S.Toolbar>
				{mainLogoMaterial}

				<S.MenuButtonMobile
					{...{
						edge: 'end',
						color: 'inherit',
						'aria-label': 'menu',
						'aria-haspopup': 'true',
						onClick: handleDrawerOpen,
					}}
				>
					<MenuIcon />
				</S.MenuButtonMobile>

				<Drawer
					{...{
						anchor: 'right',
						open: drawerOpen,
						onClose: handleDrawerClose,
					}}
				>
					<S.DrawerContainer>
						{campaignId !== 0 ? (
							!signed ? (
								<Button
									{...{
										color: 'inherit',
										to: '/auth/callback',
										component: RouterLink,
									}}
								>
									{loadingPointsBalance ? 'carregando...' : 'Login'}
								</Button>
							) : (
								<Button
									{...{
										color: 'inherit',
										to: '/conta/minhas-viagens',
										component: RouterLink,
									}}
								>
									<span className='HeaderUserSigned'>{user.name}</span>
								</Button>
							)
						) : (
							<></>
						)}
						<S.MobileDivider orientation='horizontal' />
						{getDrawerChoices()}
					</S.DrawerContainer>
				</Drawer>
			</S.Toolbar>
		);
	};

	const getDrawerChoices = () => {
		return headersData.map(({ label, href }) => {
			return (
				<Link
					{...{
						[href.substring(0, 4) === 'http' ? 'href' : 'to']: href,
						[href.substring(0, 4) === 'http' ? '' : 'component']: RouterLink,
						color: 'inherit',
						style: { textDecoration: 'none' },
						key: label,
					}}
				>
					<MenuItem>{label}</MenuItem>
				</Link>
			);
		});
	};

	const mainLogo = (
		<S.Logo variant='h6' component='h1'>
			<Link to={'/'}>
				<img
					alt='Destino Férias'
					src={
						campaignColors
							? campaignColors.logo
								? campaignColors.logo
								: campaignColors.logoDefault
							: getLogos('principal')
					}
				/>
			</Link>
		</S.Logo>
	);

	const mainLogoMaterial =
		type === 'home' ? (
			<S.LogoMaterialHome variant='h6' component='h1'>
				<Link to={'/'}>
					<img
						alt='Destino Férias'
						src={
							campaignColors
								? campaignColors.logo
									? campaignColors.logo
									: campaignColors.logoDefault
								: getLogos('material')
						}
					/>
				</Link>
			</S.LogoMaterialHome>
		) : (
			<S.LogoMaterial variant='h6' component='h1'>
				<RouterLink to={'/'}>
					<img
						alt='Destino Férias'
						src={
							campaignColors
								? campaignColors.logoSecundaria
									? campaignColors.logoSecundaria
									: campaignColors.logo
									? campaignColors.logo
									: campaignColors.logoSecundariaDefault
								: getLogos('material')
						}
					/>
				</RouterLink>
			</S.LogoMaterial>
		);

	const getMenuButtons = () => {
		return headersData.map(({ label, href }) => {
			return type === 'home' ? (
				<S.MenuButtonDesktopHome
					{...{
						key: label,
						color: 'inherit',
						[href.substring(0, 4) === 'http' ? 'href' : 'to']: href,
						[href.substring(0, 4) === 'http' ? '' : 'component']: RouterLink,
					}}
					className={`${activeButton === href ? 'active' : 'inactive'}`}
					onClick={() => setActiveButton(href)}
				>
					{label}
				</S.MenuButtonDesktopHome>
			) : (
				<S.MenuButtonDesktop
					{...{
						key: label,
						color: 'inherit',
						[href.substring(0, 4) === 'http' ? 'href' : 'to']: href,
						[href.substring(0, 4) === 'http' ? '' : 'component']: RouterLink,
					}}
					className={`${activeButton === href ? 'active' : 'inactive'}`}
					onClick={() => setActiveButton(href)}
				>
					{label}
				</S.MenuButtonDesktop>
			);
		});
	};

	return (
		<div>
			<ModalPointsBalance
				coinAlias={coinAlias}
				open={openModalPointsBalance}
				onClose={() => setOpenModalPointsBalance(false)}
			/>
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{/* <MenuItem>Perfil</MenuItem> */}
				{!user?.guest && (
					<MenuItem onClick={() => history.push('/conta/minhas-viagens')}>
						Minhas viagens
					</MenuItem>
				)}
				{user?.campaign?.ownCampaign && !user?.campaign?.disablePointActivity && (
					<MenuItem onClick={() => setOpenModalPointsBalance(true)}>
						Extrato de {coinAlias}
					</MenuItem>
				)}
				{/* <MenuItem onClick={() => history.push('/conta/minhas-viagens')}>
          Meus hoteis
        </MenuItem>
        <MenuItem onClick={() => history.push('/conta/minhas-viagens')}>
          Meus alugueis
        </MenuItem> */}
				<MenuItem
					onClick={() => {
						logout();
						history.push('/login');
					}}
				>
					Sair
				</MenuItem>
			</Menu>
			{type === 'home' ? (
				user?.campaign?.campaignConfigLayout?.homeImageBanner ? (
					<S.WrapperHomeBanner bgImg={campaignColors?.home} alt='banner home'>
						<Container>
							{mobileView ? displayMobile() : displayDesktop()}
							<a href={user?.campaign?.campaignConfigLayout?.linkHomeImage}>
								{/* <S.HeaderBanner src={campaignColors?.home} alt='banner home' /> */}
							</a>
						</Container>
					</S.WrapperHomeBanner>
				) : (
					<S.WrapperHome>
						<Container>
							{mobileView ? displayMobile() : displayDesktop()}
							{!downOfSm && (
								<S.HeaderText variant='h2'>
									{/* Experimente a melhor viagem de todas! */}
									{campaignColors.homeText
										? campaignColors.homeText
										: 'Encontre os melhores preços para sua viagem!'}
								</S.HeaderText>
							)}
						</Container>
					</S.WrapperHome>
				)
			) : (
				<S.Wrapper>
					<Container>{mobileView ? displayMobile() : displayDesktop()}</Container>
				</S.Wrapper>
			)}
		</div>
	);
}
