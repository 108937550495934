import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { pt } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main css file
// import './default.css'; // theme css file
import { addDays, addMonths, format } from 'date-fns';

import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white',
    },
    opacity: 1,
    '& .MuiInputBase-input': {
      color: `#FFF`,
    },
    '& label.Mui-focused': {
      color: `#FFF`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    '& .MuiFormLabel-root': {
      color: '#FFF',
      opacity: 0.45,
    },
    '& .MuiSelect-icon': {
      color: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&:hover fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      '&.Mui-focused fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&.Mui-error fieldset': {
        borderColor: '#FFF',
      },
    },
  },
}));
function InputDateRange({
  icon,
  label,
  onChange,
  value,
  error,
  helperText,
  minStartDays = 7,
}) {
  const classes = useStyles();

  const [controlDateRange, setControlDateRange] = useState([
    {
      startDate: addDays(new Date(), minStartDays),
      endDate: addDays(new Date(), 19),
      key: 'selection',
    },
  ]);
  const [open, setOpen] = useState(false);

  const [dataIdaVolta, setDataIdaVolta] = useState('');

  useEffect(() => {
    if (value) {
      setDataIdaVolta(value);
    }
  }, [setDataIdaVolta, value]);

  const setDatas = (datas) => {
    let weekDayStartDate = format(datas.startDate, 'EEEE dd MMMM yyyy', {
      locale: pt,
    });
    let weekDayEndDate = format(datas.endDate, 'EEEE dd MMMM yyyy', {
      locale: pt,
    });
    let startDate = datas.startDate;
    let endDate = datas.endDate;
    let dataIda = format(datas.startDate, 'dd/MM/yyyy');
    let dataVolta = format(datas.endDate, 'dd/MM/yyyy');
    let abreviada = `${format(datas.startDate, 'dd/MM')} - ${format(
      datas.endDate,
      'dd/MM'
    )}`;

    return {
      dataIda,
      dataVolta,
      abreviada,
      weekDayStartDate,
      weekDayEndDate,
      startDate,
      endDate,
    };
  };

  return (
			<>
				<Dialog
					onClose={() => setOpen(!open)}
					aria-labelledby='simple-dialog-title'
					open={open}
				>
					<S.DateRangePicker
						editableDateInputs={true}
						onChange={(item) => {
							setControlDateRange([item.selection]);
							setDataIdaVolta(setDatas(item.selection).abreviada);
							onChange(setDatas(item.selection));
						}}
						moveRangeOnFirstSelection={false}
						ranges={controlDateRange}
						locale={pt}
						minDate={addDays(new Date(), minStartDays)}
						maxDate={addMonths(new Date(), 11)}
					/>
					<Button onClick={(e) => setOpen(!open)}>OK</Button>
				</Dialog>
				<TextField
					variant='outlined'
					placeholder={label}
					readOnly
					label={label}
					value={dataIdaVolta}
					onClick={() => setOpen(!open)}
					className={classes.root}
					fullWidth
					InputProps={
						icon && {
							startAdornment: (
								<InputAdornment position='start'>
									<img alt='icon' src={icon} />
								</InputAdornment>
							),
							readOnly: true,
						}
					}
					error={error}
					helperText={helperText}
				/>
			</>
		);
}

export default InputDateRange;
