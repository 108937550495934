import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import CurrencyFormat from 'react-currency-format';

import { ThemeButton } from '../../../ThemeButton/styles';
import CarouselComponent from '../../../Carousel';
import { storage } from '../../../../services/firebase';
import { useGlobal } from '../../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../../utils/addFeeByDestinoFerias';

const ExperienceCard = ({
	title,
	description,
	valueDiscounted,
	value,
	id,
	experience,
	buscaAtual,
}) => {
	let history = useHistory();
	const [images, setImages] = useState([]);

	const { user, signed } = useGlobal();
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	useEffect(() => {
		var storageRef = storage.ref();

		var stg = storageRef.child(`experience/${experience.id}`);

		stg
			.listAll()
			.then(function (res) {
				res.items.map((itemRef) =>
					itemRef.getDownloadURL().then((url) => {
						setImages((prevState) => [...prevState, url]);
					})
				);
			})
			.catch(function (error) {
				setImages((prevState) => [
					'https://via.placeholder.com/200x200.png?text=download%20error',
				]);
			});
	}, [experience]);

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<div>
			<S.Wrapper>
				<S.CardContent container>
					<S.ExperienceDetails item xs={12}>
						<div className='carousel-container'>
							<CarouselComponent type='card' images={images} />
						</div>
						<div className='content'>
							<div className='experience-details'>
								<span className='experience-name'>{title}</span>
							</div>
							<div className='location'>
								<span>{description}</span>
							</div>
							<div>
								<span>{`O que está incluso: ${experience.whatIsIncluded}. `}</span>
								<span>{`Não está incluso: ${experience.whatIsNotIncluded}`}</span>
							</div>
						</div>
					</S.ExperienceDetails>
					<S.BottomDetails item xs={12}>
						<div>
							{valueDiscounted > 0 && (
								<span className='old-value'>
									De{' '}
									{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
										<span>
											{getValuesByUser(valueDiscounted)} {shortenCoinAlias} por{' '}
										</span>
									) : (
										<>
											{' '}
											<CurrencyFormat
												fixedDecimalScale={true}
												className='value'
												value={valueDiscounted}
												displayType={'text'}
												decimalSeparator=','
												decimalScale={2}
												prefix={'R$ '}
											/>{' '}
											por{' '}
										</>
									)}
								</span>
							)}
							<span className='final-value'>
								{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
									<span>
										{getValuesByUser(value)} {shortenCoinAlias}
									</span>
								) : (
									<CurrencyFormat
										fixedDecimalScale={true}
										value={value}
										displayType={'text'}
										decimalSeparator=','
										decimalScale={2}
										prefix={'R$ '}
									/>
								)}
							</span>
							<ThemeButton
								onClick={() => {
									sessionStorage.setItem(
										'@experience-details',
										JSON.stringify({ images, experience })
									);
									history.push('/busca/experience-details');
								}}
								className='button-select'
							>
								Detalhes
							</ThemeButton>
						</div>
					</S.BottomDetails>
				</S.CardContent>
			</S.Wrapper>
		</div>
	);
};

export default ExperienceCard;
