import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
  `}
`;

export const Error = styled.div`
  ${({ theme }) => css`
    color: red;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: auto;
    padding-top: 20px;
    margin-bottom: 50px;
    z-index: 2999;

    .button-open-filter {
      width: 100%;
      align-self: center;
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.secondary.main};
      text-transform: capitalize;
      position: fixed;
      z-index: 999;
      bottom: 0px;
      right: 0px;
      left: 0px;
    }
  `}
`;

export const LoadText = styled.span`
  ${({ theme }) => css`
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    height: 118px;
  `}
`;

export const NotFount = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    flex-direction: column;
    min-height: calc(100vh - 510px);

    .pesquisa {
      margin-top: 50px;
      height: 50px;
    }
  `}
`;
