import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core/';
import Airplane from '../../assets/icons/Airplane.svg';
import AirplaneActive from '../../assets/icons/AirplaneActive.svg';
import Car from '../../assets/icons/Car.svg';
import CarActive from '../../assets/icons/CarActive.svg';
import Bed from '../../assets/icons/Bed.svg';
import BedActive from '../../assets/icons/BedActive.svg';
import BigWheelActive from '../../assets/icons/bigWheelActive.svg';
import BigWheel from '../../assets/icons/bigWheel.svg';

import * as S from './styles';

import FlySearchInputs from './FlySearchInputs';
import HotelSearchInputs from './HotelSearchInputs';
import CarSearchInputs from './CarSearchInputs';
import ExperiencesSearchInputs from './ExperiencesSearchInputs';
import { useGlobal } from '../../contexts/globalContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <S.TabPanel
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </S.TabPanel>
  );
}

const SearchBar = () => {
  const [value, setValue] = React.useState(0);
  const { user } = useGlobal();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let arr = [];
  user?.campaign?.campaignConfigLayout?.flight &&
    arr.push({
      type: 'Aéreo',
      iconActive: AirplaneActive,
      icon: Airplane,
      component: <FlySearchInputs />,
    });
  user?.campaign?.campaignConfigLayout?.experience &&
    arr.push({
      type: 'Experiências',
      iconActive: BigWheelActive,
      icon: BigWheel,
      component: <ExperiencesSearchInputs />,
    });
  user?.campaign?.campaignConfigLayout?.hotel &&
    arr.push({
      type: 'Hotel',
      iconActive: BedActive,
      icon: Bed,
      component: <HotelSearchInputs />,
    });
  user?.campaign?.campaignConfigLayout?.car &&
    arr.push({
      type: 'Carro',
      iconActive: CarActive,
      icon: Car,
      component: <CarSearchInputs />,
    });

  useEffect(() => {
    setValue(0);
  }, []);

  return (
			<S.Wrapper>
				<Tabs
					value={value}
					onChange={handleChange}
					className='Tabs-control'
					indicatorColor='secondary'
				>
					{arr.map((x, index) => (
						<Tab
							label={x.type}
							className={`Tab-label ${value === index && 'active'}`}
							key={x.type}
							icon={
								<img
									className='Tab-image'
									src={value === index ? x.iconActive : x.icon}
									alt={x.type}
								/>
							}
						/>
					))}
				</Tabs>
				{arr.map((x, index) => (
					<TabPanel value={value} index={index} key={x.type}>
						{x.component}
					</TabPanel>
				))}
			</S.Wrapper>
		);
};

export default SearchBar;
