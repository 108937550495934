import React, { useEffect, useState } from "react";
import { Container, DialogContent, Grid } from "@material-ui/core";

import FlightFilter from "./FlightFilter";
import FlightList from "./FlightList";
import CarFilter from "./CarFilter";
import CarList from "./CarList";
import HotelFilter from "./HotelFilter";
import HotelList from "./HotelList";
import HotelListOld from "./HotelListOld";

import { useTheme, useMediaQuery, Dialog, DialogActions, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import * as S from "./styles";
import getFlights from "../../repositories/getFlights";
import { getHotels } from "../../repositories/getHotels";
import { getCars } from "../../repositories/getCars";
import { format } from "date-fns";
import { ThemeButton } from "../ThemeButton/styles";
import { useHistory } from "react-router";
import ExperienceList from "./ExperienceList";
import ExperienceFilter from "./ExperienceFilter";
import { getExperiences } from "../../repositories/getExperiences";
import HotelMap from "./HotelMap";
import HotelFilterOld from "./HotelFilterOld";

const SearchContent = ({ searchState, buscaAtual }) => {
	let history = useHistory();
	const theme = useTheme();
	const downOfSm = useMediaQuery(theme.breakpoints.down("sm"));

	const [filter, setFilter] = useState({});
	const [open, setOpen] = useState(false);

	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const changeFilter = (filters) => {
		setFilter({ ...filter, filters });
	};

	const searchFlights = (search) => {
		setLoading(true);

		let flight = {
			qtAdult: search.adultos,
			qtChild: search.criancas,
			qtBaby: search.bebes,
			directFlight: search.apenasVoosDiretos,
			luggage: search.tarifasBagagem,
		};

		if (search.idavolta) {
			flight = Object.assign(flight, {
				flightSegments: [
					{
						departureAirport: search.origem.iata,
						arrivelAirport: search.destino.iata,
						departureDate: search.datas.startDate,
					},
					{
						departureAirport: search.destino.iata,
						arrivelAirport: search.origem.iata,
						departureDate: search.datas.endDate,
					},
				],
			});
		} else {
			flight = Object.assign(flight, {
				flightSegments: [
					{
						departureAirport: search.origem.iata,
						arrivelAirport: search.destino.iata,
						departureDate: search.datas.startDate,
					},
				],
			});
		}

		getFlights(flight)
			.then((res) => {
				setResults(res.data);
				setLoading(false);
			})
			.catch((error) => {
				switch (error?.response?.status) {
					case 400:
						history.push("/session-expires");
						break;
					case 401:
						history.push("/session-expires");
						break;
					case 409:
						setError(error?.response?.data?.error);
						break;
					default:
						break;
				}
				setLoading(false);
			});
	};

	const searchHotels = (search) => {
		setLoading(true);

		let hotel = {
			checkinDate: format(new Date(search.datas.startDate), "yyyy-MM-dd"),
			checkoutDate: format(new Date(search.datas.endDate), "yyyy-MM-dd"),
			qtAdult: search.qtAdult,
			qtChild: search.qtChild,
			qtRooms: search.qtRooms,
			childAge: search.childAge,
		};

		if (search.cidade.isoCode === "BR") {
			Object.assign(hotel, {
				cityCode: search?.cidade?.parentName
					? search.cidade?.destinationCodes?.find((x) => x.codeType === "OMNI")?.code
					: search?.cidade?.code?.toString(),
			});
		} else {
			Object.assign(hotel, {
				country: search.cidade.isoCode,
				addressCode: search.cidade.code,
			});
		}

		getHotels(hotel)
			.then((res) => {
				setResults(res.data);
				setLoading(false);
				sessionStorage.setItem("hotelSearchResultsInfos", JSON.stringify(res.data));

				if (res.status === 204) {
					return setResults({
						hotelStays: [],
						error: true,
					});
				}
			})
			.catch((error) => {
				switch (error?.response?.status) {
					case 400:
						setResults({
							hotelStays: [],
							error: true,
						});
						// history.push('/session-expires');
						break;
					case 401:
						history.push("/session-expires");
						break;
					case 409:
						setError(error?.response?.data?.error);
						break;
					default:
						break;
				}
				setLoading(false);
			});
	};

	const searchCars = (search) => {
		setLoading(true);

		let car = {
			country: search.cidadeRetirada.countryIso,
			pickupAirport: search.cidadeRetirada.iata,
			pickupLocationCode: search.localRetirada.locationCode,
			pickupIsCity: search.cidadeRetirada.isCity,
			dropoffAirport: search.mesmoLocal ? search.cidadeRetirada.iata : search.cidadeDevolucao.iata,
			dropoffLocationCode: search.mesmoLocal ? search.localRetirada.locationCode : search.localDevolucao.locationCode,
			dropoffIsCity: search.mesmoLocal ? search.cidadeRetirada.isCity : search.cidadeDevolucao.isCity,
			pickupDate: format(new Date(search.datas.startDate), "yyyy-MM-dd"),
			pickupTime: search.horaRetirada,
			dropoffDate: format(new Date(search.datas.endDate), "yyyy-MM-dd"),
			dropoffTime: search.horaDevolucao,
			page: 0,
			pageItems: 20,
		};

		getCars(car)
			.then((res) => {
				setResults(res.data);
				setLoading(false);
			})
			.catch((error) => {
				switch (error?.response?.status) {
					case 400:
						history.push("/session-expires");
						break;
					case 401:
						history.push("/session-expires");
						break;
					case 409:
						setError(error?.response?.data?.error);
						break;
					default:
						break;
				}

				setLoading(false);
			});
	};

	const searchExperiences = (search) => {
		setLoading(true);
		getExperiences(
			search.cidade.id,
			format(new Date(search.datas.startDate), "yyyy-MM-dd"),
			format(new Date(search.datas.endDate), "yyyy-MM-dd")
		)
			.then((res) => {
				setResults(res.data);
				setLoading(false);
			})
			.catch((error) => {
				switch (error?.response?.status) {
					case 400:
						history.push("/session-expires");
						break;
					case 401:
						history.push("/session-expires");
						break;
					case 409:
						setError(error?.response?.data?.error);
						break;
					default:
						break;
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		buscaAtual === "Aéreo" && searchState && searchFlights(searchState);
		buscaAtual === "Hotel" && searchState && searchHotels(searchState);
		buscaAtual === "Carro" && searchState && searchCars(searchState);
		buscaAtual === "Experiencias" && searchState && searchExperiences(searchState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buscaAtual, searchState]);

	// o useEffect abaixo, é uma gambiarra que serve para renderizar os componentes em mobile, pois a lista de resultados não estava renderizando, independente das condicionais estarem corretas, porém, quando abre o modal, ele renderiza, então, abro o modal, e fecho ele, para renderizar os componentes
	useEffect(() => {
		if (downOfSm) {
			if (buscaAtual !== "Hotel")
				setTimeout(() => {
					setOpen(() => true);
					setOpen(() => false);
				}, 600);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [results]);
	return (
		<S.Wrapper>
			{!loading && error && (
				<Container>
					<S.NotFount>
						<span>{error}</span>
						<ThemeButton onClick={() => history.push("/")} className="pesquisa">
							Refazer pesquisa
						</ThemeButton>
					</S.NotFount>
				</Container>
			)}
			{!loading && buscaAtual === "Aéreo" && searchState && results && results.recommendations?.length === 0 && (
				<Container>
					<S.NotFount>
						<span>Nenhum resultado encontrado, por favor, pesquise outra data</span>
						<ThemeButton onClick={() => history.push("/")} className="pesquisa">
							Refazer pesquisa
						</ThemeButton>
					</S.NotFount>
				</Container>
			)}
			{!loading && buscaAtual === "Experiencias" && searchState && results.length === 0 && (
				<Container>
					<S.NotFount>
						<span>Nenhum resultado encontrado, por favor, pesquise outra data</span>
						<ThemeButton onClick={() => history.push("/")} className="pesquisa">
							Refazer pesquisa
						</ThemeButton>
					</S.NotFount>
				</Container>
			)}
			{loading && (
				<Container>
					<S.LoadText>Aguarde enquanto buscamos os melhores preços para você!</S.LoadText>
				</Container>
			)}
			<Dialog fullScreen={downOfSm} open={open} onClose={() => setOpen(false)}>
				<DialogContent>
					{buscaAtual === "Aéreo" && searchState && (
						<FlightFilter
							changeFilter={changeFilter}
							searchState={searchState}
							results={results}
							loading={loading}
							error={error}
							buscaAtual={buscaAtual}
						/>
					)}
					{buscaAtual === "Carro" && searchState && (
						<CarFilter
							changeFilter={changeFilter}
							searchState={searchState}
							results={results}
							loading={loading}
							error={error}
							buscaAtual={buscaAtual}
						/>
					)}
					{buscaAtual === "Hotel" && searchState && (
						<HotelFilter
							changeFilter={changeFilter}
							searchState={searchState}
							stays={results.hotelStays}
							loading={loading}
							error={error}
							buscaAtual={buscaAtual}
						/>
					)}
				</DialogContent>

				<DialogActions>
					<Button autoFocus onClick={() => setOpen(false)} color="error">
						Cancelar
					</Button>
					<Button onClick={() => setOpen(false)} color="primary" autoFocus>
						Aplicar
					</Button>
				</DialogActions>
			</Dialog>
			<Container>
				<S.Content>
					<Grid container spacing={3}>
						{!downOfSm ? (
							<Grid item md={3}>
								{buscaAtual === "Aéreo" && searchState && results && (
									<FlightFilter
										changeFilter={changeFilter}
										searchState={searchState}
										results={results}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								)}
								{buscaAtual === "Carro" && searchState && (
									<CarFilter
										changeFilter={changeFilter}
										searchState={searchState}
										results={results}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								)}
								{buscaAtual === "Experiencias" && searchState && (
									<ExperienceFilter
										changeFilter={changeFilter}
										searchState={searchState}
										results={results}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								)}
								{buscaAtual === "Hotel" &&
									searchState &&
									(searchState.cidade.isoCode !== "BR" ? (
										<HotelFilter
											changeFilter={changeFilter}
											searchState={searchState}
											stays={results.hotelStays}
											loading={loading}
											error={error}
											buscaAtual={buscaAtual}
										/>
									) : (
										<HotelFilterOld
											changeFilter={changeFilter}
											searchState={searchState}
											stays={results.hotelStays}
											loading={loading}
											error={error}
											buscaAtual={buscaAtual}
										/>
									))}
							</Grid>
						) : (
							<Button
								onClick={() => setOpen(true)}
								variant="contained"
								className="button-open-filter"
								autoFocus
								fullWidth
								startIcon={<SearchIcon />}
							>
								Aplicar filtros
							</Button>
						)}
						<Grid item xs={12} md={9}>
							{buscaAtual === "Aéreo" && searchState && results && (
								<FlightList
									filters={filter.filters}
									searchState={searchState}
									recommendations={results.recommendations}
									resumos={results?.availabilitysummary?.availabilitysummarycarrier}
									loading={loading}
									error={error}
									buscaAtual={buscaAtual}
								/>
							)}
							{buscaAtual === "Carro" && searchState && (
								<CarList
									filters={filter}
									searchState={searchState}
									results={results}
									loading={loading}
									error={error}
									buscaAtual={buscaAtual}
								/>
							)}

							{buscaAtual === "Hotel" &&
								searchState &&
								results.hotelStays &&
								!results.error &&
								(filter.filters?.mapOrList ? (
									<HotelMap
										filters={filter.filters}
										searchState={searchState}
										stays={results.hotelStays}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								) : searchState.cidade.isoCode !== "BR" ? (
									<HotelList
										filters={filter.filters}
										searchState={searchState}
										stays={results.hotelStays}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								) : (
									<HotelListOld
										filters={filter.filters}
										searchState={searchState}
										stays={results.hotelStays}
										loading={loading}
										error={error}
										buscaAtual={buscaAtual}
									/>
								))}

							{buscaAtual === "Hotel" && results.error && (
								<S.NotFount>
									<span
										style={{
											textAlign: "center",
										}}
									>
										Não foi encontrado resultado para sua busca. <br />
										Faça uma nova busca ou entre em contato com o atendimento ao cliente.
									</span>
									<ThemeButton onClick={() => history.push("/")} className="pesquisa">
										Refazer pesquisa
									</ThemeButton>
								</S.NotFount>
							)}
							{buscaAtual === "Experiencias" && searchState && (
								<ExperienceList
									filters={filter}
									searchState={searchState}
									experiences={results}
									loading={loading}
									error={error}
									buscaAtual={buscaAtual}
								/>
							)}
						</Grid>
					</Grid>
				</S.Content>
			</Container>
		</S.Wrapper>
	);
};

export default SearchContent;
